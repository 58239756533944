













































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerTutorial from '@/components/ContainerTutorial/ContainerTutorial.vue';
import Footer from '@/components/Footer/Footer.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { DATA_TUTORIAL_EXERCISE } from './ConstantTutorialsExercise';

import { OnboardingClass } from '@/mixins/Onboarding';
import { NavigationTutorials } from '@/mixins/NavigationTutorials';

@Component({
  components: {
    PageHeader,
    ContainerTutorial,
    Footer
  }
})
export default class TutorialBooks extends Mixins(OnboardingClass, NavigationTutorials) {
  private isLoading = false;

  created() {
    this.saveDataTutorials(DATA_TUTORIAL_EXERCISE);
  }

  mounted() {
    this.setBreadCrumbs();
  }

  async updateOnboardingView() {
    try {
      this.isLoading = true;
      await this.updateOnboarding(LIST_ONBOARDING_ID.BOOKS_EXERCISES);
      await this.timerUpdateProfile(this.goToBooksPage);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  goToBooksPage() {
    this.$router.push({
      name: 'exercicio',
      params: {
        context: `${this.$route.params?.context || ''}`,
        path: `${this.$route.params?.path || ''}`,
        topicID: `${this.$route.params?.topicID || ''}`,
        fileID: `${this.$route.params?.fileID || ''}`,
        slugFile: `${this.$route.params?.slugFile || ''}`
      }
    });
  }

  setBreadCrumbs() {
    const redirect = `/exercicios/${this.$route.params?.context}/${this.$route.params?.path}/${this.$route.params?.topicID}/${this.$route.params?.fileID}/${this.$route.params?.slugFile}`;

    this.$breadcrumb.set([
      { title: 'Exercício ', to: redirect },
      { title: 'Tutorial ', to: null }
    ]);
  }
}
