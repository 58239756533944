export const DATA_TUTORIAL_EXERCISE = [
  {
    id: 0,
    title: 'Olá, de novo.',
    text: '<p>Agora vamos te explicar um pouco sobre como funciona os nossos exercícios.</p>'
  },
  {
    id: 1,
    title: 'Respondendo um exercício',
    text:
      '<p>Nossos exercícios online são listas de questões que você vai responder em um determinado prazo de tempo. Além disso, é importante saber <strong>que cada exercício só poderá ser feito uma vez.</strong></p><br><p>Quando for fazer um exercício, você poderá definir <strong>o tempo</strong> em que cada questão será respondida e <strong>a forma</strong> em que essas questões irão aparecer para você.</p>',
    imgs: [
      {
        src: 'instructionExercise.png',
        alt: 'A imagem mostra a página de instrução de um exercício'
      }
    ]
  },
  {
    id: 2,
    title: 'Resultados',
    text:
      '<p>Ao final, mostramos a correção de cada questão respondida com sua resolução em texto. E, além disso, apresentamos um gráfico de desempenho apontando a estatística das alternativas marcadas pelos alunos do Explicaê.</p>',
    imgs: [
      {
        src: 'resultExercise.png',
        alt: 'A imagem mostra a página de resultado de um exercício'
      }
    ]
  },
  {
    id: 3,
    title: 'Melhorar seu desempenho',
    text:
      '<p>Além de mostrar os resultados do exercício, sugerimos depois uma lista de materiais para que você melhore o que você sentiu dificuldade.</p>',
    imgs: [
      {
        src: 'performanceExercise.png',
        alt: 'A imagem mostra a página de desempenho de um exercício'
      }
    ]
  },
  {
    id: 4,
    title: 'Conheceu nossos Exercícios?',
    text:
      '<p>Agora que já sabe como que funciona nossos exercícios. Que tal fazer uns exercícios?</p>'
  }
];
